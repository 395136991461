import { MantineColorsTuple, createTheme } from "@mantine/core";

export const pvPurple: MantineColorsTuple = [
  "#fae9ff",
  "#edcfff",
  "#d79dfd",
  "#c167fb",
  "#ae3bf9",
  "#a21ff9",
  "#9c11fa",
  "#8804df",
  "#7800c8",
  "#6900af",
];

export const pvPurpleDark: MantineColorsTuple = [
  "#f3ebff",
  "#e2d2fa",
  "#c4a0f7",
  "#a46bf5",
  "#8a3ff3",
  "#7925f2",
  "#7119f3",
  "#600fd8",
  "#550ac1",
  "#4804aa",
];

export const pvBlue: MantineColorsTuple = [
  "#defeff",
  "#caf6ff",
  "#9ceafb",
  "#6addf7",
  "#41d3f3",
  "#25ccf2",
  "#00c9f2",
  "#00b2d8",
  "#009ec2",
  "#0089aa",
];

export const pvOrange: MantineColorsTuple = [
  "#fff8e1",
  "#ffeecd",
  "#fcdb9d",
  "#fbc869",
  "#f9b73d",
  "#f9ac21",
  "#f8a710",
  "#dd9201",
  "#c58100",
  "#ab6e00",
];

export const pvRed: MantineColorsTuple = [
  "#ffece7",
  "#ffd9d0",
  "#fbb1a0",
  "#f7866d",
  "#f36241",
  "#f24b25",
  "#f23e16",
  "#d8300a",
  "#c12707",
  "#a81d01",
];

export const provisioTheme = createTheme({
  fontFamily: "Inter, sans-serif",
  colors: {
    "provisio-purple": pvPurple,
    "provisio-purple-dark": pvPurpleDark,
    "provisio-blue": pvBlue,
    "provisio-orange": pvOrange,
    "provisio-red": pvRed,
  },
});
