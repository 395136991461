import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import "@mantine/core/styles/global.css";
import "@mantine/core/styles.css";
import "@mantine/charts/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "~/preflight.css";
import "tailwindcss/tailwind.css";
import "~/fonts.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "~/app.css";

import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  defer,
  json,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetcher,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";

import Layout from "./components/layout";
import LoadingBar from "react-top-loading-bar";
import { ColorSchemeScript, MantineProvider } from "@mantine/core";
import { api } from "./api.server";
import { getSessionToken } from "./utils/auth";
import { provisioTheme } from "./theme";
import { sidebarPrefs } from "./components/sidebar/sidebar-prefs";
import useNavigationLoadingBar from "./components/use-navigation-loading-bar";
import { Notifications } from "@mantine/notifications";
import { PriorityHigh } from "@styled-icons/material-rounded";

export async function loader({ request }: LoaderFunctionArgs) {
  const cookieHeader = request.headers.get("cookie");
  try {
    const sidebarCookie = (await sidebarPrefs.parse(cookieHeader)) || {};
    const user = await api
      .withToken(await getSessionToken(request))
      .getProfile()
      .then((res) => {
        if (
          "statusCode" in res &&
          ((res.statusCode as number) < 200 ||
            (res.statusCode as number) >= 300)
        ) {
          return null;
        }
        return res;
      });
    const role = await api
      .withToken(await getSessionToken(request))
      .getSelfEmployee()
      .then((e) => e.role);
    return defer({
      user,
      sidebarState: sidebarCookie.state,
      companyLogo: null,
      role,
    });
  } catch (e) {
    return defer({ user: null, sidebarState: null, companyLogo: null });
  }
}

function App() {
  const { user, sidebarState, role } = useLoaderData<typeof loader>();
  const ref = useNavigationLoadingBar();
  const fetcher = useFetcher();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript defaultColorScheme="auto" />
      </head>
      <body>
        <LoadingBar ref={ref} color="#8404D9" height={3} />
        <MantineProvider defaultColorScheme="auto" theme={provisioTheme}>
          <Notifications />
          <Layout
            user={user}
            role={role}
            sidebarState={sidebarState}
            onSidebarStateChange={(updatedState) => {
              const formData = new FormData();
              updatedState.forEach((state) =>
                formData.append("sidebar", state),
              );
              fetcher.submit(formData, {
                action: "/dashboard",
                method: "post",
              });
            }}
          >
            <Outlet />
          </Layout>
        </MantineProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  captureRemixErrorBoundaryError(error);
  return (
    <html>
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex justify-center items-center w-screen h-screen">
          <div className="flex flex-col items-center gap-3">
            <PriorityHigh size={48} />
            <h1 className="font-bold text-lg">
              Something went wrong. Please try again later.
            </h1>
            <p>
              If the problem persists, please contact support at{" "}
              <a className="text-blue-500" href="mailto:support@provisio.app">
                support@provisio.app
              </a>
            </p>
          </div>
        </div>
        <Scripts />
      </body>
    </html>
  );
}

export async function action({ request }: ActionFunctionArgs) {
  const cookieHeader = request.headers.get("Cookie");
  const cookie = (await sidebarPrefs.parse(cookieHeader)) || {};
  const formData = await request.formData();

  const state = formData.getAll("sidebar");
  cookie.state = state;

  return json(state, {
    headers: {
      "Set-Cookie": await sidebarPrefs.serialize(cookie),
    },
  });
}

