import { useNavigation } from "@remix-run/react";
import { useRef, useEffect } from "react";
import { LoadingBarRef } from "react-top-loading-bar";

export default function useNavigationLoadingBar() {
  const navigation = useNavigation();
  const ref = useRef<LoadingBarRef>(null);
  useEffect(() => {
    if (navigation.state !== "idle") {
      ref.current?.continuousStart();
    } else {
      ref.current?.complete();
    }
  }, [navigation.state]);
  return ref;
}
