import { Image } from "@mantine/core";
import { Link } from "@remix-run/react";
import { ReactNode } from "react";

export default function Logo(): ReactNode {
  return (
    <div className="flex items-center gap-2">
      <Link to="/">
        <Image
          src="/img/logo/provisio-logo-large.png"
          alt="Logo"
          h={35}
        />
      </Link>
    </div>
  );
}
