import {
  AppShell,
  Avatar,
  Burger,
  Button,
  Divider,
  Group,
  Menu,
  NavLink,
  ScrollArea,
  Stack,
  Text,
  Badge,
  Card,
  Space,
  useMantineContext,
} from "@mantine/core";
import { useDisclosure, useElementSize, useMediaQuery } from "@mantine/hooks";
import Logo from "./logo";
import {
  RequestQuote,
  Paid,
  AccountBalance,
  GraphicEq,
  TrendingUp,
  Settings,
  Payments,
  ListAlt,
  List,
  Schedule,
  Timer,
  ViewTimeline,
  Forum,
  Groups,
  Home,
  Percent,
  CorporateFare,
  QueryStats,
  Search,
  Storefront,
  Logout,
  Savings,
  AttachMoney,
  DarkMode,
  LightMode,
} from "@styled-icons/material-rounded";
import { SoftNavLink } from "./sidebar/sidebar";
import { AccessControlRole, UserDto, UserRoleDto } from "@provisio/types";
import { Link, useMatch, useSubmit } from "@remix-run/react";
import { createMongoAbilityForEmployee } from "@provisio/access";

type LayoutProps = {
  children?: React.ReactNode;
  user?: UserDto | null;
  sidebarState?: string[];
  onSidebarStateChange?: (state: string[]) => void;
  role: AccessControlRole;
};

export default function Layout({
  children,
  user,
  sidebarState,
  onSidebarStateChange,
  role,
}: LayoutProps) {
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure(
    sidebarState?.includes("mobile-opened")
  );
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(
    !sidebarState?.includes("desktop-closed")
  );
  const { ref } = useElementSize();
  const isDashboard = useMatch("/dashboard/*");

  const ability = createMongoAbilityForEmployee({ role });

  const onEntryClick = () => {
    toggleMobile();
    if (onSidebarStateChange) {
      onSidebarStateChange(
        mobileOpened
          ? [...(sidebarState || []), "mobile-opened"]
          : (sidebarState || []).filter((s) => s !== "mobile-opened")
      );
    }
  };

  const onOpenChange = (id: string) => {
    return (state: boolean) => {
      if (onSidebarStateChange) {
        onSidebarStateChange(
          state
            ? [...(sidebarState || []), id]
            : (sidebarState || []).filter((s) => s !== id)
        );
      }
    };
  };

  const submit = useSubmit();

  const { colorScheme, setColorScheme } = useMantineContext();

  return (
    <AppShell
      header={{ height: { base: 60 } }}
      navbar={
        isDashboard && user
          ? {
              width: 300,
              breakpoint: "sm",
              collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
            }
          : undefined
      }
    >
      <AppShell.Header className="flex">
        <Group h={60} px="md" w={{ md: desktopOpened ? 300 : "fit" }}>
          {isDashboard && user && (
            <>
              <Burger
                opened={mobileOpened}
                onClick={() => {
                  toggleMobile();
                  if (onSidebarStateChange) {
                    onSidebarStateChange(
                      mobileOpened
                        ? [...(sidebarState || []), "mobile-opened"]
                        : (sidebarState || []).filter(
                            (s) => s !== "mobile-opened"
                          )
                    );
                  }
                }}
                hiddenFrom="sm"
                size="sm"
              />
              <Burger
                opened={desktopOpened}
                onClick={() => {
                  toggleDesktop();
                  if (onSidebarStateChange) {
                    onSidebarStateChange(
                      desktopOpened
                        ? [...(sidebarState || []), "desktop-closed"]
                        : (sidebarState || []).filter(
                            (s) => s !== "desktop-closed"
                          )
                    );
                  }
                }}
                visibleFrom="sm"
                size="sm"
              />
            </>
          )}
          <Group
            align="center"
            display={{
              base: "none",
              md: "block",
            }}
          >
            <Logo />
          </Group>
        </Group>

        {isDashboard && user && (
          <Group
            id="dashboard-page-header"
            className="h-full grow"
            align="center"
            pl="md"
            pr="lg"
          ></Group>
        )}

        {!isDashboard && user && (
          <Group justify="end" w="100%" pr="lg">
            <Link to="/dashboard" prefetch="intent">
              <Button variant="outline" color="grape" size="sm">
                Go to Dashboard
              </Button>
            </Link>
          </Group>
        )}

        {!isDashboard && !user && (
          <Group justify="end" gap="sm" w="100%" pr="lg">
            <Link to="/login" prefetch="intent">
              <Button variant="outline" color="provisio-blue.9" size="sm">
                Login
              </Button>
            </Link>
            <Link to="/onboarding" prefetch="intent">
              <Button color="provisio-purple-dark" size="sm">
                Register
              </Button>
            </Link>
          </Group>
        )}

        {user && (
          <Group justify="end" pr="lg">
            <Menu position="left-start" trigger="hover">
              <Menu.Target>
                <Avatar size="md">
                  {user?.firstName?.charAt(0).toUpperCase()}
                  {user?.lastName?.charAt(0).toUpperCase()}
                </Avatar>
              </Menu.Target>

              <Menu.Dropdown w={300}>
                <Card>
                  <Stack gap={0}>
                    <Group>
                      <Text size="sm">
                        {user.firstName} {user.lastName}
                      </Text>
                      <Badge color="provisio-blue.9" size="sm" radius="sm">
                        {user.role === UserRoleDto.ADMIN ? "Admin" : "Employee"}
                      </Badge>
                    </Group>
                    <Text size="xs" c="gray">
                      {user.email}
                    </Text>
                  </Stack>
                </Card>

                <Menu.Item
                  leftSection={
                    colorScheme === "dark" ? (
                      <DarkMode size={20} />
                    ) : (
                      <LightMode size={20} />
                    )
                  }
                  onClick={() => {
                    setColorScheme(colorScheme === "dark" ? "light" : "dark");
                  }}
                >
                  Toggle Theme
                </Menu.Item>

                <Menu.Item
                  leftSection={<Logout size={20} />}
                  color="provisio-red"
                  onClick={() => {
                    submit(null, { action: "/logout", method: "POST" });
                  }}
                >
                  Log Out
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        )}
      </AppShell.Header>

      {isDashboard && (
        <AppShell.Navbar ref={ref} className="gap-2" component={ScrollArea}>
          <SidebarEntryChildren
            isOpen={sidebarState?.includes("projects")}
            parentIcon={<List size={20} />}
            parentLabel="Project Management"
            onEntryClick={onEntryClick}
            onOpenChange={onOpenChange("projects")}
            items={[
              {
                href: "/dashboard/projects",
                title: "Projects",
                Icon: ListAlt,
                id: "projects",
              },
              {
                href: "/dashboard/tasks",
                title: "Tasks",
                Icon: List,
                id: "tasks",
              },
              {
                href: "/dashboard/timeline",
                title: "Timeline",
                Icon: ViewTimeline,
                id: "timeline",
                hiddenOnMobile: true,
              },
              {
                href: "/dashboard/projects/settings/templates",
                title: "Project Templates",
                Icon: Settings,
                id: "project-settings",
              },
            ]}
          />
          <Divider />
          <SidebarEntryChildren
            isOpen={sidebarState?.includes("time-tracking")}
            parentIcon={<Schedule size={20} />}
            parentLabel="Time Tracking"
            onEntryClick={onEntryClick}
            onOpenChange={onOpenChange("time-tracking")}
            items={[
              {
                href: "/dashboard/time",
                title: "Track",
                Icon: Timer,
                id: "time-tracking",
              },
              {
                href: "/dashboard/time/sheets",
                title: "Timesheets",
                Icon: ViewTimeline,
                id: "time-sheets",
                hiddenOnMobile: true,
              },
            ]}
          />
          <Divider />
          <SidebarEntryChildren
            isOpen={sidebarState?.includes("billing")}
            parentLabel="Billing & Accounts"
            parentIcon={<Payments size={20} />}
            onEntryClick={onEntryClick}
            onOpenChange={onOpenChange("billing")}
            items={[
              ...(ability.can("view", "Invoice")
                ? [
                    {
                      href: "/dashboard/billing/invoices",
                      title: "Invoices",
                      Icon: RequestQuote,
                      id: "invoices",
                    },
                  ]
                : []),

              ...(ability.can("view", "Payment")
                ? [
                    {
                      href: "/dashboard/billing/payments",
                      title: "Payments",
                      Icon: Paid,
                      id: "payments",
                    },
                  ]
                : []),

              ...(ability.can("view", "Statement")
                ? [
                    {
                      href: "/dashboard/billing/statements",
                      title: "Statements",
                      Icon: AccountBalance,
                      id: "financial-statements",
                    },
                  ]
                : []),

              ...(ability.can("view", "AgeAnalysis")
                ? [
                    {
                      href: "/dashboard/billing/age-analysis",
                      title: "Age Analysis",
                      Icon: GraphicEq,
                      id: "age-analysis",
                    },
                  ]
                : []),

              ...(ability.can("view", "TrendAnalysis")
                ? [
                    {
                      href: "/dashboard/billing/trend-analysis",
                      title: "Trend Analysis",
                      Icon: TrendingUp,
                      id: "trend-analysis",
                    },
                  ]
                : []),

              ...(ability.can("view", "Payroll")
                ? [
                    {
                      href: "/dashboard/billing/payroll",
                      title: "Payroll",
                      Icon: AttachMoney,
                      id: "payroll",
                    },
                  ]
                : []),
            ]}
          />
          <Divider />
          <SidebarEntryChildren
            isOpen={sidebarState?.includes("employees")}
            parentIcon={<Groups size={20} />}
            parentLabel="Employees"
            onEntryClick={onEntryClick}
            onOpenChange={onOpenChange("employees")}
            items={[
              {
                href: "/dashboard/employees/directory",
                title: "Employee Directory",
                Icon: ListAlt,
                id: "employee-directory",
              },
              ...(user?.role === UserRoleDto.ADMIN
                ? [
                    {
                      href: "/dashboard/employees/utilisation",
                      title: "Utilisation",
                      Icon: Percent,
                      id: "utilisation",
                    },
                  ]
                : []),
              {
                href: "/dashboard/employees/leave-management",
                title: "Leave Management",
                Icon: Home,
                id: "leave-management",
              },
              {
                href: "/dashboard/employees/feedback",
                title: "Feedback and Surveys",
                Icon: Forum,
                id: "feedback",
              },
            ]}
          />
          <Divider />
          <SidebarEntryChildren
            isOpen={sidebarState?.includes("company")}
            parentIcon={<CorporateFare size={20} />}
            parentLabel="Company"
            onEntryClick={onEntryClick}
            onOpenChange={onOpenChange("company")}
            items={[
              {
                href: "/dashboard/company/client-customer-database",
                title: "Client & Customer Database",
                Icon: Storefront,
                id: "clients",
              },

              {
                href: "/dashboard/company/project-leads",
                title: "Project Leads",
                Icon: Search,
                id: "project-leads",
              },

              ...(ability.can("view", "Asset")
                ? [
                    {
                      href: "/dashboard/company/assets",
                      title: "Assets",
                      Icon: Savings,
                      id: "assets",
                    },
                  ]
                : []),

              ...(ability.can("view", "FinancialOverview")
                ? [
                    {
                      href: "/dashboard/company/financial-overview/income",
                      title: "Financial Overview",
                      Icon: Paid,
                      id: "financial-overview",
                    },
                  ]
                : []),

              ...(ability.can("view", "FinancialOverview")
                ? [
                    {
                      href: "/dashboard/company/analytics",
                      title: "Company Reports & Analytics",
                      Icon: QueryStats,
                      id: "analytics",
                    },
                  ]
                : []),

              ...(ability.can("view", "CompanySettings")
                ? [
                    {
                      href: "/dashboard/company/settings",
                      title: "Company Settings",
                      Icon: Settings,
                      id: "company-settings",
                    },
                  ]
                : []),
            ]}
          />
          <Space h={20} />
        </AppShell.Navbar>
      )}
      <AppShell.Main bg="var(--mantine-color-body)" component={Stack}>
        {children}
      </AppShell.Main>
    </AppShell>
  );
}

function SidebarEntryChildren({
  items,
  onEntryClick,
  parentIcon,
  parentLabel,
  isOpen,
  onOpenChange,
}: {
  items: {
    href: string;
    title: string;
    Icon: typeof Settings;
    id: string;
    hiddenOnMobile?: boolean;
  }[];
  onEntryClick?: (id: string) => void;
  parentIcon: React.ReactNode;
  parentLabel: string;
  isOpen?: boolean;
  onOpenChange?: (state: boolean) => void;
}) {
  const isMobile = useMediaQuery("(max-width: 768px)", true, {
    getInitialValueInEffect: false,
  });

  return (
    <NavLink
      color="grape"
      label={parentLabel}
      leftSection={parentIcon}
      childrenOffset={28}
      onChange={onOpenChange}
      defaultOpened={isOpen}
    >
      {items.map((item) => (
        <SoftNavLink
          matchHandle={item.id}
          key={item.href}
          to={item.href}
          disabled={isMobile && item.hiddenOnMobile}
        >
          {({ isActive }) => (
            <NavLink
              disabled={isMobile && item.hiddenOnMobile}
              component="div"
              key={item.title}
              active={isActive}
              label={item.title}
              leftSection={<item.Icon size="1rem" />}
              onClick={() => {
                if (onEntryClick) onEntryClick(item.id);
              }}
              color="provisio-purple-dark"
              description={
                isMobile && item.hiddenOnMobile
                  ? "Unavailable on mobile"
                  : undefined
              }
            />
          )}
        </SoftNavLink>
      ))}
    </NavLink>
  );
}
