import { Link, useMatches } from "@remix-run/react";

export function SoftNavLink({
  to,
  children,
  matchHandle,
  disabled = false,
}: {
  to: string;
  matchHandle: string;
  children: (params: { isActive: boolean }) => React.ReactNode;
  disabled?: boolean;
}) {
  const matches = useMatches();
  const isActive = matches.some((match) => {
    if (typeof match.handle === "object") {
      if (match.handle && "linkId" in match.handle) {
        return match.handle.linkId === matchHandle;
      }
    }
  });

  if (disabled) {
    return children({ isActive: false });
  }

  return (
    <Link to={to} prefetch="intent">
      {children({
        isActive: !!isActive,
      })}
    </Link>
  );
}
